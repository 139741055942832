import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { css } from '@emotion/core'
import Layout from '../components/Layout'
import { H1, H2 } from '../components/Titles'
import { Card, CardGrid } from '../components/Card'
import src from '../images/illustration_all.svg'
export default (
  {
    // data: {
    //   file: {
    //     childImageSharp: {
    //       resize: { src },
    //     },
    //   },
    // },
  }
) => (
  <Layout>
    <Helmet>
      <title>WorldLinx - About</title>
    </Helmet>
    <H1>About</H1>
    <CardGrid>
      <Card>
        <H2>WorldLinx Alliance</H2>{' '}
        <p>
          WORLDLINX ALLIANCE or WLA is active since ten years in AERIAL RELAY
          production. Two aircrafts BEECHCRAFT B200 are equipped with certified
          pods housing up to 7 dynamic reception/transmission antennas. Each
          antenna can receive several sources They are also fitted with 3m arms,
          allowing transmission over a distance up to 200km.
        </p>
        <p>
          WORLDLINX ALLIANCE can propose also backup solutions with a third
          aircraft Beechcraft B200 or B90 and some other relay aircrafts if
          needed.
        </p>
      </Card>
      <Card>
        <H2>Location</H2>
        <p>
          WORLDLINX ALLIANCE is based in Antwerp, Belgium, but operates on all
          continents. The aircrafts operated apart from Europe, for instance in
          Argentina, Australia, Chile, USA, Mexico, Middle East and many places
          all over the globe.
        </p>
      </Card>
      <Card>
        <H2>Strategy</H2>
        <p>
          WLA is independent and clients range from public broadcasters, TV
          facility providers to federations or event organizers. The aerial
          relay solutions that WLA provide are ideal for TV production of
          Rallies, Cycling, Marathon, Triathlons but also for any event. The
          aircrafts were for instance used over Paris after the Presidential
          elections, gathering a dozen of TV sources on the ground and
          transmitting these to the networks headquarters.
        </p>
        <p>
          Jacques Hendrickx is the overall manager of WLA. For commercial issues
          you can contact Gabriel Fehervari. WLA also has a team of pilots. For
          all technical questions, please contact Jens Vanhoof. He is also a key
          Pilot.
        </p>
      </Card>
    </CardGrid>
    <img
      src={src}
      css={css`
        overflow: hidden;
        width: 100%;
      `}
    />
  </Layout>
)
// export const query = graphql`
//   {
//     file(name: { eq: "illustration_all" }) {
//       childImageSharp {
//         resize(width: 800) {
//           src
//         }
//       }
//     }
//   }
// `
